<template>
  <div class="BIMcontainer">
    <div ref="BIMParent" class="BIMcontainer">
      <iframe
        ref="iframeCesium"
        allowtransparency="true"
        :src="`${$config.public.config.iTwinBimCompareIframeUrl}/?projectID=${projectId}&type=bim-compare&user=admin&cameraID=${cameraExid}`"
        :style="iframeStyle"
        v-bind="$attrs"
        v-on="$listeners"
        @load="onLoaded"
      ></iframe>
    </div>
  </div>
</template>

<script>
import LAYER_TYPES from "@evercam/shared/constants/layerTypes"
import { EvercamApi } from "@evercam/shared/api/evercamApi"

export default {
  name: "BimViewer",
  props: {
    projectId: {
      type: String,
      default: "",
    },
    cameraExid: {
      type: String,
      default: "",
    },
    timestamp: {
      type: String,
      default: "",
    },
  },
  data: () => ({
    iframeStyle: {},
    transparentAreas: "",
    iframeReady: false,
  }),
  watch: {
    timestamp(value) {
      this.getBimMaskLayers(value)
    },
  },
  mounted() {
    window.onresize = this.updateFrameDimensions
    this.$addEventListener("message", this.handleReceiveMessage)
  },
  methods: {
    handleReceiveMessage(event) {
      if (
        event.origin === this.$config.public.iTwinBimCompareIframeUrl &&
        event.data?.type === "ready"
      ) {
        this.iframeReady = true
        this.getBimMaskLayers(this.timestamp)
      }
    },
    onLoaded() {
      this.updateFrameDimensions()
    },
    updateFrameDimensions() {
      this.$clearTimeouts()
      this.$setTimeout(() => {
        this.iframeStyle = {
          height: "100%",
          width: "100%",
        }
      }, 1000)
    },
    sendTransparencyAreas() {
      if (this.iframeReady) {
        this.$refs.iframeCesium?.contentWindow?.postMessage(
          {
            type: "transparentArea",
            areas: this.transparentAreas,
          },
          this.$config.public.iTwinBimCompareIframeUrl
        )
      }
    },
    async getBimMaskLayers(date) {
      try {
        const { shapes } = await EvercamApi.layers.getLayers(this.cameraExid, {
          timestamp: date,
          layerType: LAYER_TYPES.BIM_MASK,
        })
        if (shapes) {
          this.transparentAreas = shapes
          this.sendTransparencyAreas()
        }
      } catch (e) {
        if (e.response?.status !== 404) {
          this.$errorTracker.save(e)
        }
      }
    },
  },
}
</script>

<style scoped>
.BIMcontainer {
  height: 100%;
  width: 100%;
  margin: 0px !important;
  padding: 0px !important;
}
</style>
