<template>
  <div class="bim-viewer">
    <ERow dense align="stretch">
      <ECol cols="10">
        <v-card>
          <EZoomableImg
            ref="img"
            :src="cameraImage"
            class="itwin-compare"
            disabled
            :slider="false"
            :overlay-style="{ zIndex: 1 }"
            :is-widget="accountStore.isWidget"
          >
            <template v-if="cameraImage" #imageOverlay>
              <ITwinBimCompare
                :projectId="cameraDialogStore.camera.projectExid"
                :cameraExid="cameraDialogStore.camera.exid"
                :timestamp="timestamp"
              />
            </template>
          </EZoomableImg>
        </v-card>
      </ECol>

      <ECol cols="2">
        <v-date-picker
          v-model="snapshotDateTime"
          no-title
          color="#1976d2"
          full-width
          box-shadow
          :picker-date.sync="pickerDate"
          :allowed-dates="allowedDates"
          @click:date="getSnapshot"
        />
        <v-divider></v-divider>
        <HourPicker
          :time-picker="snapshotHour"
          :allowed-hours="allowedHours"
          :date="snapshotDateTime"
          @change="changeHour"
        />
      </ECol>
    </ERow>
  </div>
</template>

<script>
import HourPicker from "@evercam/shared/components/HourPicker"
import { EvercamApi } from "@evercam/shared/api/evercamApi"
import ITwinBimCompare from "@/components/iTwin/ITwinBimCompare"
import { AdminApi } from "@evercam/shared/api/adminApi"
import { mapStores } from "pinia"
import { useSnapshotStore } from "@/stores/snapshots"
import { useCameraDialogStore } from "@/stores/cameraDialog"
import { useBreadcrumbStore } from "@/stores/breadcrumb"
import { useAccountStore } from "@evercam/dashboard/stores/account"

export default {
  name: "BimViewer",
  components: {
    HourPicker,
    ITwinBimCompare,
  },
  asyncData({ params }) {
    useBreadcrumbStore().breadcrumbs = [
      {
        icon: "fa-solid fa-home",
        href: "/",
      },
      {
        name: "Bim",
        href: "/bim-compare",
      },
      {
        name: params.exid,
        href: `/bim-compare/${params.exid}/layers`,
      },
      {
        name: "ITwin BIM Compare",
      },
    ]
  },
  data() {
    return {
      cameraImage: null,
      snapshotDateTime: null,
      snapshotHour: null,
      loadingDate: true,
      allowedHours: [],
      disabledDays: [],
      pickerDate: null,
      timestamp: "",
    }
  },
  computed: {
    ...mapStores(useSnapshotStore, useCameraDialogStore, useAccountStore),
  },
  watch: {
    pickerDate(val) {
      if (this.cameraDialogStore.camera) {
        const pickerDateMonth = val.split("-")[1]
        const currentDateMonth = this.snapshotDateTime?.split("-")[1]
        if (pickerDateMonth !== currentDateMonth) {
          this.snapshotDateTime = val + "-01"
        }
        this.getSnapshot()
      }
    },
  },
  async created() {
    if (!this.cameraDialogStore.camera.exid) {
      const cameras = await AdminApi.cameras.getCameras({
        params: {
          cameraExid: this.$route.params.exid,
        },
      })
      this.cameraDialogStore.camera = cameras.items[0]
    }
    this.snapshotDateTime = this.$moment
      .tz(this.getTimezone(this.cameraDialogStore.camera.timezone))
      .format("YYYY-MM-DD")
    this.snapshotHour = this.$moment
      .tz(this.getTimezone(this.cameraDialogStore.camera.timezone))
      .format("HH")
    this.cameraImage = null
    await this.snapshotStore.updateFrames({
      camera: this.cameraDialogStore.camera.exid,
      apiKey: this.cameraDialogStore.camera.userApiKey,
      apiId: this.cameraDialogStore.camera.userApiId,
    })
    this.getSnapshot()
  },
  methods: {
    getDisableDates() {
      let dateTime = this.$moment(this.snapshotDateTime)
      let year = dateTime.format("YYYY")
      let month = dateTime.format("MM")

      EvercamApi.recordings
        .availableDays({
          cameraId: this.cameraDialogStore.camera.exid,
          year,
          month,
          payload: {
            apiKey: this.cameraDialogStore.camera?.userApiKey,
            apiId: this.cameraDialogStore.camera?.userApiId,
          },
        })
        .then((response) => {
          let disabledDaysValue = []
          let days = response.days
          days.forEach((day) => {
            disabledDaysValue.push(`${year}-${month}-${this.formatNumTo2(day)}`)
          })
          if (days.length > 0) {
            this.getDisableHours()
          } else {
            this.allowedHours = []
          }
          this.disabledDays = disabledDaysValue
        })
        .catch((error) => {
          this.loadingDate = false
          this.$emit(
            "error",
            "An error occurred fetching snapshot days. Please try again and, if the problem persists, contact support.",
            error
          )
        })
    },
    getDisableHours() {
      const date = this.$moment(this.snapshotDateTime + " " + this.snapshotHour)
      const lastLoadedDay = date.format("DD")
      let year = date.format("YYYY")
      let month = date.format("MM")
      EvercamApi.recordings
        .availableHours({
          cameraId: this.cameraDialogStore.camera.exid,
          year,
          month,
          day: lastLoadedDay,
          payload: {
            apiKey: this.cameraDialogStore.camera?.userApiKey,
            apiId: this.cameraDialogStore.camera?.userApiId,
          },
        })
        .then((response) => {
          this.allowedHours = response.hours
        })
    },
    getSnapshot() {
      if (this.snapshotDateTime && this.snapshotHour) {
        this.timestamp = this.snapshotDateTime + "T" + this.snapshotHour
        this.timestamp = this.$moment
          .tz(
            this.timestamp,
            this.getTimezone(this.cameraDialogStore.camera.timezone)
          )
          .toISOString()
      } else {
        this.timestamp = this.$moment()
          .set({ minute: 0, second: 0, millisecond: 0 })
          .toISOString()
      }
      EvercamApi.recordings
        .nearest(this.cameraDialogStore.camera.exid, `${this.timestamp}`, {
          apiKey: this.cameraDialogStore.camera?.userApiKey,
          apiId: this.cameraDialogStore.camera?.userApiId,
        })
        .then((response) => {
          if (response.snapshots.length !== 0) {
            this.cameraImage = response.snapshots[0].data
          }
        })
      this.getDisableDates()
    },
    formatNumTo2(n) {
      if (parseInt(n) < 10) {
        return `0${n}`
      } else {
        return n
      }
    },
    getTimezone(timezone) {
      return timezone || "Europe/Dublin"
    },
    changeHour(hour) {
      this.snapshotHour = hour
      this.getSnapshot()
    },
    allowedDates(val) {
      return this.disabledDays?.find((v) => v === val)
    },
  },
}
</script>

<style lang="scss" scoped>
.bim-viewer {
  &__main > v-sheet {
    height: 100vh;
  }
  .left_panel {
    margin-right: 15px;
    margin-left: 15px;
  }
  .itwin-compare {
    height: calc(100vh - 100px);
  }
}
</style>
